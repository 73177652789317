import type { BootData } from '@grafana/data';
import { getBackendSrv, config } from '@grafana/runtime';

import { Settings as LuxonSettings } from 'luxon';

interface Settings {
  settings: {
    apps?: {
      [key: string]: {
        id: string;
        path: string;
        version: string;
        preload: boolean;
        angularDetected: boolean;
      };
    };
  };
}

export type ExtendedGrafanaBootData = BootData & Settings;
export interface PrefrencesResponse {
  language: string;
  timezone: string;
  weekStart: string;
}

void (async () => {
  const tz = await getUserPreferences().then((res) => res?.timezone);
  const newTz = tz == null || tz === 'browser' ? Intl.DateTimeFormat().resolvedOptions().timeZone : tz;
  LuxonSettings.defaultZone = newTz;
})();

export async function getUserPreferences(): Promise<PrefrencesResponse | undefined> {
  const extendedConfig = config.bootData as unknown as ExtendedGrafanaBootData;
  if (extendedConfig.user?.permissions?.['orgs.preferences:read'] === true) {
    return getBackendSrv().get('/api/org/preferences');
  } else {
    return undefined;
  }
}
