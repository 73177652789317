import React, { type ComponentClass, Suspense, lazy } from 'react';

import { setClient } from '@grafana-irm/core';
import {
  AppPlugin,
  type AppPluginMeta,
  type AppRootProps,
  type PluginConfigPageProps,
  PluginExtensionPoints,
  type PluginExtensionPanelContext,
} from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Spinner } from '@grafana/ui';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { CURRENT_VERSION } from 'version';

import { GrafanaInternalApiClient } from 'api/GrafanaApiClient';
import type { GrafanaIncidentSettings } from 'types';
import { getIsIrmPluginPresent } from 'utils/pluginId';

setClient(new GrafanaInternalApiClient());

const DeclareIncidentForm = lazy(() => import('pages/DeclareIncident/DeclareIncidentForm'));
const App = lazy(() => import('pages/App'));
const AppConfig = lazy(() => import('pages/AppConfig'));
const MinimalAppContextProvider = lazy(() => import('api/MinimalAppContextProvider'));

if ('serviceWorker' in navigator) {
  /**
   * Service Workers: An Introduction and Purpose in This Project
   *
   * A service worker is a script run by the browser in the background, separate from the web page,
   * enabling features like asset caching, offline access, push notifications, and background syncing.
   *
   * In this project, we are registering a service worker to achieve the following goals:
   *
   * 1. Old version access:
   *    Service workers allow us to cache assets and data, enabling the application to work when the
   *    server no longer has our application.
   *
   * 2. **Performance Optimization**:
   *    By caching essential assets, we can significantly improve the loading time of our application,
   *    making it more responsive and faster to load on repeat visits.
   *
   * NOTE: Service workers require a secure origin (HTTPS) and are not fully supported in older browsers.
   * NOTE: We use workbox-webpack-plugin to generate our service-worker.js which supports dev mode and many other great features!
   */
  navigator.serviceWorker
    .register(`/public/plugins/grafana-incident-app/service-worker.js?v=${CURRENT_VERSION}`)
    .then((registration) => {
      console.info('ServiceWorker registration successful with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('ServiceWorker registration failed:', error);
    });
} else {
  console.error('ServiceWorker registration failed, no navigator.serviceWorker');
}

function AppWithLoader(props: AppRootProps<GrafanaIncidentSettings>): React.ReactElement {
  const queryClient = new QueryClient();
  return (
    <Suspense fallback={<PluginPage children={<Spinner />} />}>
      <QueryClientProvider client={queryClient}>
        <MinimalAppContextProvider>
          <App {...props} />
        </MinimalAppContextProvider>
      </QueryClientProvider>
    </Suspense>
  );
}

// NOTE: Used by grafana code, so not an used export
// ts-prune-ignore-next
const plugin = new AppPlugin<GrafanaIncidentSettings>()
  .setRootPage(AppWithLoader as unknown as ComponentClass<AppRootProps<GrafanaIncidentSettings>, unknown>)
  .addConfigPage({
    title: 'Configuration',
    icon: 'cog',
    body: AppConfig as unknown as ComponentClass<
      PluginConfigPageProps<AppPluginMeta<GrafanaIncidentSettings>>,
      unknown
    >,
    id: 'incident-configuration',
  });

if (
  'configureExtensionLink' in plugin &&
  PluginExtensionPoints != null &&
  'DashboardPanelMenu' in PluginExtensionPoints &&
  !getIsIrmPluginPresent()
) {
  plugin.configureExtensionLink<PluginExtensionPanelContext>({
    title: 'Declare incident',
    category: 'Incident',
    icon: 'fire',
    description: 'Declare an incident and attach the panel context to it',
    extensionPointId: PluginExtensionPoints.DashboardPanelMenu,
    onClick: async (_, { openModal, context }) => {
      if (context == null) {
        return;
      }
      const queryClient = new QueryClient();
      const panelURL =
        context.dashboard.uid != null
          ? `${window.location.href}&dashboardId=${context.dashboard.uid}&panelId=${context.id.toString()}`
          : '';

      openModal({
        title: 'Declare incident',
        body: ({ onDismiss: handleDismiss }) => (
          <Suspense fallback={<Spinner />}>
            <QueryClientProvider client={queryClient}>
              <MinimalAppContextProvider>
                <DeclareIncidentForm
                  onDismiss={handleDismiss}
                  attachURL={panelURL}
                  incidentSource="panel"
                  defaultLabels={[]}
                />
              </MinimalAppContextProvider>
            </QueryClientProvider>
          </Suspense>
        ),
      });
    },
  });
}

if (
  'configureExtensionLink' in plugin &&
  PluginExtensionPoints != null &&
  'ExploreToolbarAction' in PluginExtensionPoints &&
  !getIsIrmPluginPresent()
) {
  plugin.configureExtensionLink<PluginExtensionPanelContext>({
    title: 'Declare incident',
    description: 'Declare an incident',
    extensionPointId: PluginExtensionPoints.ExploreToolbarAction,
    category: 'Incident',
    icon: 'fire',
    onClick: async (_, { openModal, context }) => {
      if (context == null) {
        return;
      }
      const queryClient = new QueryClient();
      openModal({
        title: 'Declare incident',
        body: ({ onDismiss: handleDismiss }) => (
          <Suspense fallback={<Spinner />}>
            <QueryClientProvider client={queryClient}>
              <MinimalAppContextProvider>
                <DeclareIncidentForm onDismiss={handleDismiss} incidentSource="explore" defaultLabels={[]} />
              </MinimalAppContextProvider>
            </QueryClientProvider>
          </Suspense>
        ),
      });
    },
  });
}

export { plugin };
