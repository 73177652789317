import type { GrafanaBootConfig } from '@grafana/runtime';

export const PluginIdMap = {
  Incident: 'grafana-incident-app',
  Irm: 'grafana-irm-app',
} as const;
const possiblePluginIds = Object.values(PluginIdMap);

type PluginId = (typeof PluginIdMap)[keyof typeof PluginIdMap];

export const getPluginId = (): PluginId => {
  try {
    const pluginId = process.env.PLUGIN_ID as PluginId;

    if (pluginId === ('' as PluginId)) {
      return PluginIdMap.Incident;
    } else if (!possiblePluginIds.includes(pluginId)) {
      return PluginIdMap.Incident;
    }

    return pluginId;
  } catch (error) {
    return PluginIdMap.Incident;
  }
};

export const getIsIrmPluginPresent = (): boolean =>
  PluginIdMap.Irm in (window.grafanaBootData?.settings as GrafanaBootConfig).apps;
