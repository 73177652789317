/* eslint-disable camelcase */

import { z } from 'zod';

export type ResponderOption = 'schedules' | 'teams' | 'users';

export const OncallTeamObjectSchema = z
  .object({
    id: z.number().or(z.literal('null').or(z.string()).or(z.null())).optional(),
    name: z.string(),
    email: z.string().nullable().optional(),
    avatar_url: z.string().nullable().optional(),
    is_sharing_resources_to_all: z.boolean().optional(),
  })
  .partial();

export type OnCallTeam = z.infer<typeof OncallTeamObjectSchema>;

export const OnCallUserSchema = z
  .object({
    pk: z.string(),
    organization: z.object({ pk: z.string(), name: z.string() }),
    current_team: z.null(),
    teams: z.array(OncallTeamObjectSchema).default([]).readonly(),
    email: z.string(),
    username: z.string(),
    name: z.string(),
    role: z.number(),
    avatar: z.string(),
    avatar_full: z.string(),
    timezone: z.string().nullable(),
    working_hours: z.object({
      friday: z.array(z.object({ end: z.string(), start: z.string() })),
      monday: z.array(z.object({ end: z.string(), start: z.string() })),
      sunday: z.array(z.object({ end: z.string(), start: z.string() })),
      tuesday: z.array(z.object({ end: z.string(), start: z.string() })),
      saturday: z.array(z.object({ end: z.string(), start: z.string() })),
      thursday: z.array(z.object({ end: z.string(), start: z.string() })),
      wednesday: z.array(z.object({ end: z.string(), start: z.string() })),
    }),
    unverified_phone_number: z.null(),
    verified_phone_number: z.null(),
    slack_user_identity: z.null(),
    telegram_configuration: z.null(),
    messaging_backends: z.object({
      MOBILE_APP: z.object({ connected: z.boolean() }),
      MOBILE_APP_CRITICAL: z.object({ connected: z.boolean() }),
      EMAIL: z.object({ email: z.string() }),
    }),
    notification_chain_verbal: z.object({
      default: z.string(),
      important: z.string(),
    }),
    cloud_connection_status: z.number(),
    hide_phone_number: z.boolean(),
    is_currently_oncall: z.boolean().optional(),
  })
  .partial();
export type OnCallUser = z.infer<typeof OnCallUserSchema>;

export const OnCallUserPageSchema = z
  .object({
    next: z.number().or(z.string()).nullable(),
    previous: z.number().or(z.string()).nullable(),
    results: z.array(OnCallUserSchema),
    page_size: z.number(),
    count: z.number(),
    current_page_number: z.number(),
    total_pages: z.number(),
  })
  .partial();

export type OnCallUserPage = z.infer<typeof OnCallUserPageSchema>;

export const PagedUserSchema = z
  .object({
    name: z.string(),
    username: z.string(),
    pk: z.string(),
    id: z.string().or(z.number()).optional(),
    avatar: z.string().optional(),
    avatar_full: z.string().optional(),
    important: z.boolean().nullable(),
    teams: z.array(OncallTeamObjectSchema).default([]),
  })
  .partial();

export type PagedUserType = z.infer<typeof PagedUserSchema>;

const AlertGroupRefSchema = z.object({
  pk: z.string(),
  render_for_web: z
    .object({
      title: z.string(),
      message: z.string(),
      image_url: z.string().nullable(),
      source_link: z.string().nullable(),
    })
    .nullable(),
  alert_receive_channel: z.object({
    id: z.string(),
    integration: z.string(),
    verbal_name: z.string(),
    deleted: z.string(),
  }),
  inside_organization_number: z.number(),
});

export const OnCallAlertGroupSchema = z
  .object({
    pk: z.string(),
    alerts_count: z.number(),
    inside_organization_number: z.number(),
    alert_receive_channel: z.object({
      id: z.string(),
      integration: z.string(),
      verbal_name: z.string(),
      deleted: z.boolean(),
    }),
    resolved: z.boolean(),
    resolved_by: z.number(),
    resolved_by_user: z.object({ pk: z.string(), username: z.string() }).nullable(),
    resolved_at: z.string().nullable(),
    acknowledged_at: z.string().nullable(),
    acknowledged: z.boolean(),
    acknowledged_on_source: z.boolean(),
    acknowledged_by_user: z.object({ pk: z.string(), username: z.string() }).nullable(),
    silenced: z.boolean(),
    silenced_by_user: z.object({ pk: z.string(), username: z.string() }).nullable(),
    silenced_at: z.string().nullable(),
    silenced_until: z.string().nullable(),
    started_at: z.string(),
    related_users: z.array(
      z.object({
        username: z.string(),
        pk: z.string(),
        avatar: z.string(),
        avatar_full: z.string(),
      })
    ),
    render_for_web: z
      .object({
        title: z.string(),
        message: z.string(),
        image_url: z.string().nullable(),
        source_link: z.string().nullable(),
      })
      .nullable(),
    render_for_classic_markdown: z
      .string()
      .or(
        z.object({
          title: z.string(),
          message: z.string(),
          image_url: z.string().nullable(),
          source_link: z.string().nullable(),
        })
      )
      .nullable(),
    dependent_alert_groups: AlertGroupRefSchema.array(),
    root_alert_group: AlertGroupRefSchema.nullable(),
    status: z.number(),
    declare_incident_link: z.string(),
    team: z.string().nullable(),
    is_restricted: z.boolean(),
    alerts: z.array(
      z.object({
        id: z.string(),
        link_to_upstream_details: z.string().nullable(),
        render_for_web: z
          .string()
          .nullable()
          .or(
            z.object({
              title: z.string(),
              message: z.string(),
              image_url: z.string().nullable(),
              source_link: z.string().nullable(),
            })
          ),
        created_at: z.string(),
      })
    ),
    render_after_resolve_report_json: z
      .array(
        z.object({
          time: z.string(),
          action: z.string(),
          realm: z.string(),
          type: z.number(),
          created_at: z.string(),
          author: z
            .object({
              username: z.string(),
              pk: z.string(),
              avatar: z.string(),
              avatar_full: z.string(),
            })
            .nullable(),
        })
      )
      .nullable(),
    slack_permalink: z.string().nullable(),
    permalinks: z.object({
      slack: z.string().nullable(),
      telegram: z.string().nullable(),
      web: z.string(),
    }),
    last_alert_at: z.string(),
    paged_users: z.array(PagedUserSchema),
  })
  .partial();

export type OnCallAlertGroup = z.infer<typeof OnCallAlertGroupSchema>;

// Array of user or team IDs, together with the Direct-Page ID; kept in an incident ref so we can find them again later
export const DpIdSchema = z.tuple([z.enum(['team', 'user']), z.string(), z.string()]);
